.fileContainer {
  flex: 0 0 3em;
  aspect-ratio: 1 / 1;
  border-radius: 0.6em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filePictureContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileLink {
  display: block;
}

.fileLink,
.filePicture {
  max-width: 100%;
  max-height: 100%;
}

.actionsContainer {
  height: 0;
  visibility: hidden;
  position: relative;

  .fileContainer:hover & {
    visibility: visible;
  }
}

.actionContainer {
  width: 100%;
  aspect-ratio: 1 / 1;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
