@use './vars.scss';
@use './recordWrapper.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--app-background);
  color: lightgray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

#root {
  background: linear-gradient(#282c34f8, #282c34f8), url(./img/backgroundWhite.png);
  background-size: 15%;
}

a {
  color: #61dafb;
}

textarea {
  color: inherit;
}

.horizontalLine {
  border-top: 1px solid #363c49
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
  margin-left: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.08);
  border: 1px solid #282c34;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.14);
}

::-webkit-scrollbar-button {
  display: none;
}

@supports not(selector(::-webkit-scrollbar)) {
  * {
    scrollbar-color: rgba(0, 0, 0, 0.08) transparent;
    scrollbar-width: thin;
  }
}

.actionBtn {
  background-color: #434853;
  border-radius: .5em;
  border: none;
  outline: none;
  height: 3em;
  aspect-ratio: 1/1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #d3d3d3;

  &:focus,
  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }

  &:active {
    filter: brightness(80%);
  }

  svg {
    font-size: 1.3em;
  }
}

.recordWrapper {
  @include recordWrapper.recordWrapper;
}
