.editRecordContainer {
  width: 100%;
}

.editingHeader {
  padding-top: 0.5em;
  padding-bottom: 1.2em;
  margin-bottom: -1em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  justify-content: space-between;
}
