.record {
  display: flex;
  flex-direction: column;
}

.header {
  font-weight: bold;
  font-size: 1.5em;
}

.body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.recordContent {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.recordControl {
  width: 0;
  height: 0;
  overflow: visible;
  position: relative;
}

.menuToggler {
  $btnSize: 2em;

  background: none;
  border: none;
  outline: none;
  color: inherit;
  padding: 0.3px;
  font-size: 1em;
  height: $btnSize;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: calc(-1em - $btnSize / 2);
  background: linear-gradient(var(--app-background) 0%, var(--record-background-color) 100%);
  display: none;

  .record.active &,
  .record:focus &,
  .record:hover & {
    display: initial;
  }
}

.menuAnchor {
  position: absolute;
  right: 0;
  top: calc(-0.5em);
}
