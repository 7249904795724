@use "sass:color";

$background-l0: #282c34;
$background-l1: color.scale($background-l0, $lightness: 2%);
$background-l2: color.scale($background-l0, $lightness: 5%);
// TODO: Need to add also versions of border color. Lighter background require lighter border.

:root {
  --app-background: #{$background-l0};

  --record-background-color: #{$background-l1};
  --record-border-color: #363c49;

  --menu-background-color: #{$background-l2};
}
