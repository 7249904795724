.app {
  width: 100%;
  max-width: 900px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 1em 1em;
  gap: 1em;
  box-sizing: border-box;
}
