.anchor {
  width: 40px;
  height: 40px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  z-index: 10;
}

.item {
  margin-top: calc(-10px - 100%);

  &:first-of-type {
    margin-top: 0;
  }

  .anchor.open & {
    margin-top: 0;
  }
}