.listContainer {
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  flex: 1 1 auto;
}

.recordList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}
