@use './../../../styles/recordWrapper.scss';

.infoWrapper {
  @include recordWrapper.recordWrapper;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.error {
  background-color: #452525;
  border-color: #7f0303;
}

.warn {
  background-color: #393100;
  border-color: #7f6e03;
}
