.composerContainer {
  display: flex;
  gap: 1em;
  flex-direction: column;
}

.textInputContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 1em;
  align-items: flex-end;
}

.textInput {
  flex: 1 1 auto;
  background-color: transparent;
  width: 100%;
  border: none;
  resize: none;
  overflow: auto;
  outline: none;
  box-sizing: border-box;
  max-height: 12em;
  min-height: 3em;
}

.btnsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
}
