.menuContainer {
  border: 1px solid var(--record-border-color);
  border-radius: 0.5em;
  background-color: var(--menu-background-color);
}

.menuItem {
  background: none;
  border: none;
  outline: none;
  color: inherit;
  font-size: inherit;
  white-space: nowrap;
  padding: 0.8em 1.3em;
  cursor: pointer;
  background-color: var(--menu-background-color);
  width: 100%;

  &:focus,
  &:hover {
    filter: brightness(90%);
  }

  &:active {
    filter: brightness(80%);
  }
}
