.clearBtn {
  background: none;
  border: none;
  outline: none;
  color: inherit;
  padding: 0;
  font-size: 1em;
  height: 1.5em;
  aspect-ratio: 1 / 1;
  cursor: pointer;
}
